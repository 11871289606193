import React from "react";
import browserHistory from "../../helper/browserHistory";
import {observable, toJS} from "mobx";
import {inject, observer} from "mobx-react";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import EditSimpleValueComponent from "../../components/moap-components/EditSimpleValueComponent";
import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";
import CardImageMetaTitleComponent from "../../components/card/CardImageMetaTitleComponent";
import CardImageMetaTitleMailComponent from "../../components/card/CardImageMetaTitleMailComponent";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Accordion from "semantic-ui-react/dist/commonjs/modules/Accordion";

let scrollToComponent = function () {
    return;
};

if (typeof document !== "undefined") {
    scrollToComponent = require('react-scroll-to-component');
}

const PAGE_LINK = "Über uns";


const stifter = ["Brauerei Leibinger GmbH ", "Dr. Dietrich Dörner ", "Druckerei Stein GmbH & Co. KG ", "Erich Gabur ", "Dr. Franz-Georg Grenz ", "IMMO-HYP GmbH ",
    "Dr. Alexander Ivanovas ", "Kreissparkasse Ravensburg ", "Heinz Walser ", "Michael Kübel ", "Ursula Löfflmann ", "Ralph Michelberger ", "Edwin Müller ",
    "Tox Pressotechnik GmbH & Co. KG ", "Dr. Thilo Wagner ", "Dreiländerklinik GmbH ", "Reiner Fritz ", "Sigrid Netzer ", "Uwe Rieger ", "Michael Kleiser ",
    "Simone Wohlwender ", "Franz Birk ", "Dr. Inge Mauch-Frohn ", "Peter Lickert  ", "Thomas Lickert  ", "Sylvia Lickert ",
    "Manfred Laub ", "Martha Bentele ", "Marianne Näger ", "Guido Wieland ", "Eleonore Wieland ", "Annemarie Blacha ", "Claus Wieland ",
    "Kurt Peter ", "Renate Peter ", "EnBW Regional AG ", "Gerd Gerber ", "Juliane Gabur ", "Calendula Hospizgruppe e. V. Wangen imAllgäu ",
    "Heinz Pumpmeier ", "Dr. Friederike Ivanovas ", "Aveo Konferenzsysteme GmbH ", "Torsten Stefan ", "Bärbel Paul ", "Roland Paul ", "Michael Horn ",
    "Brigitte Horn ", "Franz Koller ", "Klaus Peter Stroh ", "Renate Betz-Much ", "Peter Striegel ", "Simone Striegel ", "Albert Wasmeier ", "Elisabeth Gröber ",
    "Gerd Gröber ", "Sven Oberpottkamp ", "Isolde Wohlfrom ", "Peter Wohlfrom ", "Werner Spaeth ", "Elfi Spaeth ", "Markus Kloos ", "Monika Kerschbaum ", "Luitgard Schirm ",
    "Jürgen Schirm ", "Sandra Hangleiter ", "Christoph Hangleiter ", "Dr. Andreas Wolf ", "Nina Nobilis", "Joachim Sautter", "Eva-Maria Meschenmoser", "Prof. Dr. Franz Maurer",
    "Prof. Dr. Stefan König", "Maria Theresia Butz-Kobel", "Friedrich Kobel", "Rosa Maria Seren", "Dr. Peter Vischer", "Claudia Rombach", "Wolfgang Rombach", "Florian Burk", "Jürgen Mossakowski",
    "Vera Mossakowski", "Engelbert Knitz", "Theresia Knitz", "Dr. Boris Ivanovas", "Linus Brugger", "Heribert Brugger", "Dr. Nelida Nowak", "Dr. Karl Nowak", "Dr. Kilian Fuchs", "André Schopies",
    "Thomas Scherrieb", "Anfried Baier-Fuchs", "Gabriele Reiter", "Rolf Mayer", "Gabi Legner-Sautter", "Michael Bräuning","Dr. Nikolaus Ivanovas"];

@inject("stores") @observer
class AboutPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            textStore: props.stores.textStore.texts, isLoading: true,
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.stores.trackingStore.addVisit(PAGE_LINK);
        let location = this.props.location;
        /*
         Check if a param is given to scroll to wanted section
         */
        if (location !== undefined && location.hasOwnProperty("search")) {
            let search = location.search;
            if (search.startsWith("?") && search.length > 1) {
                let section = search.substr(1);
                switch (section) {
                    case "0":
                        this.activeItems = [0];
                        scrollToComponent(this.zero, {offset: 0, align: 'top', duration: 0});
                        break;
                    case "1":
                        this.activeItems = [5];
                        scrollToComponent(this.one, {offset: 0, align: 'top', duration: 0});
                        break;
                    case "2":
                        this.activeItems = [4];
                        scrollToComponent(this.two, {offset: 0, align: 'top', duration: 0});
                        break;
                    default:
                        break;

                }
            }
        }
    }


    @observable
    activeItems = [0];

    toggleAccordionItem(item) {
        if (this.activeItems.includes(item)) {
            this.activeItems.splice(this.activeItems.indexOf(item), 1);
        } else {
            this.activeItems.push(item);
        }
        this.setState({});
    }

    render() {
        const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";
        const Organigramm = "https://www.buergerstiftung-kreis-rv.de/images/BSKR_Organigramm_2024.png";

        let showStift = stifter.map((stift, index) => <Grid.Column computer={4} tablet={8} mobile={16} stretched
                                                                   key={index}>
            <Card fluid className={"card-shadow bottom-card-padding"}>
                <Card.Content>
                    <Card.Meta>STIFTER</Card.Meta>
                    <Card.Header className={"card-header card-extra-break"}>
                        <h3>
                            {stift}
                        </h3>
                    </Card.Header>
                </Card.Content>
            </Card>
        </Grid.Column>);
        return (<Grid>
                <TitleImageComponent
                    textStore={this.state.textStore}
                    namespace={'about'}
                    imageLinkId={"about-title-image-id"}
                    headerId={"about-title-header-id"}
                    subheaderId={"about-subtitle-header-id"}
                />
                <Grid.Row>
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Breadcrumb className={"breadcrumb-container"}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                browserHistory.push("/");
                                this.setState({});
                            }}>
                                HOME</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section className={"active-breadcrumb nav-item-yellow"}>
                                ÜBER UNS
                            </Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row"}>
                    <Grid.Column computer={12} tablet={14} mobile={16}>
                        <Accordion
                            defaultActiveIndex={toJS(this.activeItems)}
                            fluid
                            exclusive={false}>
                            <Accordion.Title
                                className={"accordion-title"}
                                onClick={() => this.toggleAccordionItem(0)}>

                                <EditSimpleValueComponent id={"about-sector-2-title-id"} namespace={'about'}
                                                          textStore={this.state.textStore}/>
                                <Icon
                                    size={"big"}
                                    className={"accordion-icon"}
                                    name={this.activeItems.includes(0) ? 'angle up' : 'angle down'}/>
                            </Accordion.Title>
                            <Accordion.Content active={this.activeItems.includes(0)}>
                                <Grid>
                                    <Grid.Row className={"page-row"}>
                                        <Grid.Column width={16}>
                                            <Image centered
                                                   src={Seperator}
                                                   size={"tiny"}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row centered className={"page-row-bottom"}>
                                        <Grid.Column width={16}>
                                            <p className={"text-block"}>
                                                <EditHTMLComponent textStore={this.state.textStore}
                                                                   id={"about-sector-2-subtitle-id"}
                                                                   namespace={'about'}/>
                                            </p>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row centered stretched>
                                        <Grid.Column computer={3} tablet={5} mobile={6}>
                                            <CardImageMetaTitleComponent
                                                textStore={this.state.textStore}
                                                namespace={'about'}
                                                metaText={"board-meta-1"}
                                                nameText={"board-name-1"}
                                                imageLink={"board-image-1"}
                                            />
                                        </Grid.Column>
                                        <Grid.Column computer={3} tablet={5} mobile={6}>
                                            <CardImageMetaTitleComponent
                                                textStore={this.state.textStore}
                                                namespace={'about'}
                                                metaText={"board-meta-2"}
                                                nameText={"board-name-2"}
                                                imageLink={"board-image-2"}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row centered className={"page-row-bottom"} stretched>
                                        <Grid.Column computer={3} tablet={5} mobile={6}>
                                            <CardImageMetaTitleComponent
                                                textStore={this.state.textStore}
                                                namespace={'about'}
                                                metaText={"board-meta-3"}
                                                nameText={"board-name-3"}
                                                imageLink={"board-image-3"}
                                            />
                                        </Grid.Column>
                                        <Grid.Column computer={3} tablet={5} mobile={6}>
                                            <CardImageMetaTitleComponent
                                                textStore={this.state.textStore}
                                                namespace={'about'}
                                                metaText={"board-meta-5"}
                                                nameText={"board-name-5"}
                                                imageLink={"board-image-5"}
                                            />
                                        </Grid.Column>
                                        <Grid.Column computer={3} tablet={5} mobile={6}>
                                            <CardImageMetaTitleComponent
                                                textStore={this.state.textStore}
                                                namespace={'about'}
                                                metaText={"board-meta-6"}
                                                nameText={"board-name-6"}
                                                imageLink={"board-image-6"}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Accordion.Content>

                            <Accordion.Title
                                className={"accordion-title"}
                                onClick={() => this.toggleAccordionItem(2)}>
                                <EditSimpleValueComponent id={"about-sector-21-title-id"} namespace={'about'}
                                                          textStore={this.state.textStore}/>
                                <Icon
                                    size={"big"}
                                    className={"accordion-icon"}
                                    name={this.activeItems.includes(2) ? 'angle up' : 'angle down'}/>
                            </Accordion.Title>
                            <Accordion.Content active={this.activeItems.includes(2)}>
                                <Grid>
                                    <Grid.Row className={"page-row"}>
                                        <Grid.Column width={16}>
                                            <Image centered
                                                   src={Seperator}
                                                   size={"tiny"}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row centered className={"page-row-bottom"}>
                                        <Grid.Column width={16}>
                                            <p className={"text-block"}>

                                                <EditHTMLComponent textStore={this.state.textStore}
                                                                   id={"about-sector-21-subtitle-id"}
                                                                   namespace={'about'}/>
                                            </p>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row centered stretched>
                                        <Grid.Column computer={3} tablet={5} mobile={6}>
                                            <CardImageMetaTitleComponent
                                                textStore={this.state.textStore}
                                                namespace={'about'}
                                                metaText={"board-meta-11"}
                                                nameText={"board-name-11"}
                                                imageLink={"board-image-11"}
                                            />
                                        </Grid.Column>
                                        <Grid.Column computer={3} tablet={5} mobile={6}>
                                            <CardImageMetaTitleComponent
                                                textStore={this.state.textStore}
                                                namespace={'about'}
                                                metaText={"board-meta-21"}
                                                nameText={"board-name-21"}
                                                imageLink={"board-image-21"}
                                            />
                                        </Grid.Column>
                                        <Grid.Column computer={3} tablet={5} mobile={6}>
                                            <CardImageMetaTitleComponent
                                                textStore={this.state.textStore}
                                                namespace={'about'}
                                                metaText={"board-meta-51"}
                                                nameText={"board-name-51"}
                                                imageLink={"board-image-51"}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row centered stretched className={"page-row-bottom"}>
                                        <Grid.Column computer={3} tablet={5} mobile={6}>
                                            <CardImageMetaTitleComponent
                                                textStore={this.state.textStore}
                                                namespace={'about'}
                                                metaText={"board-meta-31"}
                                                nameText={"board-name-31"}
                                                imageLink={"board-image-31"}
                                            />
                                        </Grid.Column>
                                        <Grid.Column computer={3} tablet={5} mobile={6}>
                                            <CardImageMetaTitleComponent
                                                textStore={this.state.textStore}
                                                namespace={'about'}
                                                metaText={"board-meta-41"}
                                                nameText={"board-name-41"}
                                                imageLink={"board-image-41"}
                                            />
                                        </Grid.Column>
                                        <Grid.Column computer={3} tablet={5} mobile={6}>
                                            <CardImageMetaTitleComponent
                                                textStore={this.state.textStore}
                                                namespace={'about'}
                                                metaText={"board-meta-61"}
                                                nameText={"board-name-61"}
                                                imageLink={"board-image-61"}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Accordion.Content>
                            <Accordion.Title
                                className={"accordion-title"}
                                onClick={() => this.toggleAccordionItem(3)}>

                                <EditSimpleValueComponent id={"about-sector-22-title-id"} namespace={'about'}
                                                          textStore={this.state.textStore}/>
                                <Icon
                                    size={"big"}
                                    className={"accordion-icon"}
                                    name={this.activeItems.includes(3) ? 'angle up' : 'angle down'}/>
                            </Accordion.Title>
                            <Accordion.Content active={this.activeItems.includes(3)}>
                                <Grid>
                                    <Grid.Row className={"page-row"}>
                                        <Grid.Column width={16}>
                                            <Image centered
                                                   src={Seperator}
                                                   size={"tiny"}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row centered className={"page-row-bottom"}>
                                        <Grid.Column width={16}>
                                            <p className={"text-block"}>

                                                <EditHTMLComponent textStore={this.state.textStore}
                                                                   id={"about-sector-22-subtitle-id"}
                                                                   namespace={'about'}/>
                                            </p>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row centered>
                                        {showStift}
                                    </Grid.Row>
                                </Grid>
                            </Accordion.Content>
                            <Accordion.Title
                                className={"accordion-title"}
                                onClick={() => this.toggleAccordionItem(4)}>

                                <EditSimpleValueComponent id={"about-sector-4-title-id"} namespace={'about'}
                                                          textStore={this.state.textStore}/>
                                <section ref={(section) => {
                                    this.two = section;
                                }}/>
                                <Icon
                                    size={"big"}
                                    className={"accordion-icon"}
                                    name={this.activeItems.includes(4) ? 'angle up' : 'angle down'}/>
                            </Accordion.Title>
                            <Accordion.Content active={this.activeItems.includes(4)}>
                                <Grid>
                                    <Grid.Row className={"page-row"}>
                                        <Grid.Column width={16}>
                                            <Image centered
                                                   src={Seperator}
                                                   size={"tiny"}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row centered className={"page-row-bottom"}>
                                        <Grid.Column width={16}>
                                            <p className={"text-block"}>

                                                <EditHTMLComponent textStore={this.state.textStore}
                                                                   id={"about-sector-4-text-id"}
                                                                   namespace={'about'}/>
                                            </p>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row centered stretched className={"page-row-bottom"}>
                                        <Grid.Column computer={4} tablet={8} mobile={8}>
                                            <CardImageMetaTitleMailComponent
                                                textStore={this.state.textStore}
                                                namespace={'about'}
                                                metaText={"board-meta-27"}
                                                nameText={"board-name-27"}
                                                imageLink={"board-image-27"}
                                                mailAddress={"board-mail-27"}
                                                class={"portrait"}
                                            />
                                        </Grid.Column>
                                        <Grid.Column computer={4} tablet={8} mobile={8}>
                                            <CardImageMetaTitleMailComponent
                                                textStore={this.state.textStore}
                                                namespace={'about'}
                                                metaText={"board-meta-37"}
                                                nameText={"board-name-37"}
                                                imageLink={"board-image-37"}
                                                mailAddress={"board-mail-37"}
                                                class={"portrait"}
                                            />
                                        </Grid.Column>
                                        <Grid.Column computer={4} tablet={8} mobile={8}>
                                            <CardImageMetaTitleMailComponent
                                                textStore={this.state.textStore}
                                                namespace={'about'}
                                                metaText={"board-meta-47"}
                                                nameText={"board-name-47"}
                                                imageLink={"board-image-47"}
                                                mailAddress={"board-mail-47"}
                                                class={"portrait"}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Accordion.Content>

                            <Accordion.Title
                                className={"accordion-title"}
                                onClick={() => this.toggleAccordionItem(1)}>

                                <EditSimpleValueComponent id={"about-sector-1-title-id"} namespace={'about'}
                                                          textStore={this.state.textStore}/>
                                <section ref={(section) => {
                                    this.zero = section;
                                }}/>
                                <Icon
                                    size={"big"}
                                    className={"accordion-icon"}
                                    name={this.activeItems.includes(1) ? 'angle up' : 'angle down'}/>
                            </Accordion.Title>
                            <Accordion.Content active={this.activeItems.includes(1)}>
                                <Grid>
                                    <Grid.Row className={"page-row"}>
                                        <Grid.Column width={16}>
                                            <Image centered
                                                   src={Seperator}
                                                   size={"tiny"}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row className={"page-row-bottom"}>
                                        <Grid.Column width={16}>
                                            <p className={"text-block"}>
                                                <EditHTMLComponent textStore={this.state.textStore}
                                                                   id={"about-sector-1-text-id"}
                                                                   namespace={'about'}/>
                                            </p>
                                            <figure>
                                                <Image className={"margin-auto"}
                                                       src={"https://www.buergerstiftung-kreis-rv.de/images/blog-images/Projektausschuss_202310-Kamera.jpg"}
                                                       alt={"Projektausschuss"}/>
                                                <br/>
                                                <figcaption style={{margin: "auto", maxWidth: "1080px"}}>Der
                                                    Projektausschuss trifft sich einmal im Monat, um über
                                                    Förderanfragen zu entscheiden und Empfehlungen an den Vorstand
                                                    auszusprechen. Die Mitglieder v. l. n. r.:
                                                    <br/>
                                                    <em>Dr. Gertie Abt, Max
                                                        Vogler, Liv Pfluger, Joachim Sautter, Sonja Vochezer, Jessica
                                                        Kohlbauer und Robert Heer</em>.
                                                </figcaption>
                                            </figure>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Accordion.Content>

                            <Accordion.Title
                                className={"accordion-title"}
                                onClick={() => this.toggleAccordionItem(5)}>

                                <EditSimpleValueComponent id={"about-sector-23-title-id"} namespace={'about'}
                                                          textStore={this.state.textStore}/>
                                <section ref={(section) => {
                                    this.one = section;
                                }}/>
                                <Icon
                                    size={"big"}
                                    className={"accordion-icon"}
                                    name={this.activeItems.includes(5) ? 'angle up' : 'angle down'}/>
                            </Accordion.Title>
                            <Accordion.Content active={this.activeItems.includes(5)}>
                                <Grid>
                                    <Grid.Row className={"page-row"}>
                                        <Grid.Column width={16}>
                                            <Image centered
                                                   src={Seperator}
                                                   size={"tiny"}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row centered className={"page-row-bottom"}>
                                        <Grid.Column width={16}>
                                            <p className={"text-block"}>

                                                <EditHTMLComponent textStore={this.state.textStore}
                                                                   id={"about-sector-23-subtitle-id"}
                                                                   namespace={'about'}/>
                                            </p>
                                            <figure>
                                                <Image className={"margin-auto"}
                                                       src={"https://www.buergerstiftung-kreis-rv.de/images/site-images/Gründung_Bürgerstiftung_1.jpg"}
                                                       alt={"Gründer"}/>
                                                <br/>
                                                <figcaption style={{margin: "auto", maxWidth: "1080px"}}>v.l.n.r.:
                                                    <em>Gründungsstifter und Ehrenvorstand Dr. Alexander
                                                        Ivanovas</em> mit den
                                                    zwei weiteren <em>Gründungsstiftern Ursula Löfflmann</em> sowie
                                                    <em>Kreissparkasse Ravensburg, vertreten durch Heinz Pumpmeier</em>.

                                                </figcaption>
                                            </figure>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Accordion.Content>
                            <Accordion.Title
                                className={"accordion-title"}
                                onClick={() => this.toggleAccordionItem(6)}>

                                <EditSimpleValueComponent id={"about-sector-3-title-id"} namespace={'about'}
                                                          textStore={this.state.textStore}/>

                                <Icon
                                    size={"big"}
                                    className={"accordion-icon"}
                                    name={this.activeItems.includes(6) ? 'angle up' : 'angle down'}/>
                            </Accordion.Title>
                            <Accordion.Content active={this.activeItems.includes(6)}>
                                <Grid>
                                    <Grid.Row centered className={"page-row"}>
                                        <Grid.Column width={16}>
                                            <Image
                                                onClick={() => window.open(Organigramm, "_blank")}
                                                style={{cursor: "pointer"}}
                                                src={Organigramm}
                                                fluid
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Accordion.Content>
                            <Accordion.Title
                                className={"accordion-title"}
                                onClick={() => this.toggleAccordionItem(7)}>

                                <EditSimpleValueComponent id={"about-sector-24-title-id"} namespace={'about'}
                                                          textStore={this.state.textStore}/>

                                <Icon
                                    size={"big"}
                                    className={"accordion-icon"}
                                    name={this.activeItems.includes(7) ? 'angle up' : 'angle down'}/>
                            </Accordion.Title>
                            <Accordion.Content active={this.activeItems.includes(7)}>
                                <Grid>
                                    <Grid.Row centered className={"page-row"}>
                                        <Grid.Column width={16}>
                                            <p className={"text-block"}>
                                                <EditHTMLComponent id={"about-sector-24-text-id"} namespace={'about'}
                                                                   textStore={this.state.textStore}/>
                                            </p>
                                            <h4 className="text-center" style={{color: "#004e84"}}>Stiftungsfonds</h4>
                                            <p className="text-center text-block-blue">
                                                <EditSimpleValueComponent id={"about-sector-24-sub-text-id"}
                                                                          textStore={this.state.textStore}
                                                                          namespace={"about"}/>
                                            </p>
                                            <h4 className="text-center" style={{color: "#004e84"}}>Treuhandstiftungen</h4>
                                            <p className={"text-center text-block-blue"}>
                                                <EditSimpleValueComponent id={"about-sector-25-sub-text-id"}
                                                                          textStore={this.state.textStore}
                                                                          namespace={"about"}/>
                                            </p>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Accordion.Content>
                        </Accordion>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        );
    }
}

export default AboutPage